export class ShoppingCardOffer {
	offerNumber : string;
	offerId : number;
	finishingTime?: string | null;
	elements : ShoppingCardElement[];
}

export class ShoppingCardElement {
	serialNo : string;
	articleId : string;
	articleName : string;
	serviceProductId : string;
	services : ShoppingCardServices[];
}

export class ShoppingCardServices {
	offerId : number;
	positionId : number;
	posNum : number;
	name : string;
	description : string;
    info: string;
}
