import { Component, OnInit, Input } from '@angular/core';
import { FullElasticIndex, ReportService } from '@esders/connect-api';
import { GuiOperatorsService } from '@esders/gui';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs';
import { downloadFile } from '../generic-download-button/generic-download-button.component';
import { AnalyticsService, TrackEventDownload } from '@esders/analytics';

@Component({
	selector: 'app-report-download',
	templateUrl: './report-download.component.html',
	styleUrls: ['./report-download.component.scss']
})

export class ReportdownloadComponent implements OnInit {
	@Input() detail: FullElasticIndex = {};

	public downloadGenerate: boolean = false;

	constructor(
		private _reportService : ReportService,
		private _translate : TranslateService,
		private _rxjs : GuiOperatorsService,
		private _analyticsService: AnalyticsService,
	) {
	}

	ngOnInit(): void {
	}

	public download() {
		this.downloadGenerate = true;

		this._reportService.singleReportPdf(this._translate.currentLang, this.detail, "response").pipe(
			finalize(() => this.downloadGenerate = false),
			this._rxjs.catchHttpErrors(),
			take(1)
		).subscribe(pdfResponse => {
			const contentDisposition = pdfResponse.headers.get('content-disposition');
			let filename = new Date().toISOString() + ".pdf";

			if(contentDisposition) {
				filename = contentDisposition.split(';')[1].split('filename')[1].
				split('=')[1].replace('\"', '').replace('\"', '');
			}

			this._analyticsService.trackEvent(new TrackEventDownload({
				name: filename,
				action: "meas-pdf"
			}));

			downloadFile(pdfResponse.body, filename);
		});
	}
}
