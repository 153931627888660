<button class="icon" (click)="!isSingleReport ? DownloadPDF() : null" [matMenuTriggerFor]="!isSingleReport ? null : menu">
	{{'t_download' | translate}} 
	<mat-icon [ngClass]="{'hide': this.downloadGenerate}">get_app</mat-icon>
	<mat-icon class="spin" [ngClass]="{'hide': !this.downloadGenerate}">cached</mat-icon>
</button>

<mat-menu #menu="matMenu">
	<button mat-menu-item (click)=DownloadPDF()>
		<span>pdf</span>
	</button>
	<button mat-menu-item (click)=DownloadXLSX()>
		<span>Excel</span>
	</button>
	<button mat-menu-item (click)=DownloadCSV()>
		<span>csv</span>
	</button>
</mat-menu>
