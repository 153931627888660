import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[valuerow]'
})
export class ValueRowDirective {

	constructor(public valuerowref: ViewContainerRef) {

	}

}
