import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-favouriteStar',
	templateUrl: './favouriteStar.component.html',
	styleUrls: ['./favouriteStar.component.scss']
})
export class FavouriteStarComponent implements OnInit {
	@Input() value: boolean = false;
	@Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit() {
	}

	public changeValue() {
		this.value = !this.value;
		this.valueChange.emit(this.value);
	}
}
