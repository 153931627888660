<div class="row" #view>
	<mat-form-field appearance="fill" class="col s12 m4 l4" matTooltip="{{'t_filter_des' | translate }}" matTooltipPosition="above">
		<mat-label translate>t_property</mat-label>
		<mat-select [formControl]="selectedFilter" >
			<!-- Damit das close von dem icon nicht im Text steht! -->
			<mat-select-trigger>
				{{selectedFilter.value ? (selectedFilter.value.translateField | translate) : ''}}
			</mat-select-trigger>

			<mat-option *ngFor="let result of ASP" [value]="result">
				<div class="option">
					<div class="optiontext">{{result.translateField | translate}}</div>
					<i class="trash material-icons" (click)="onRemoveASP(result)">close</i>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field appearance="fill" class="col s12 m4 l3">
		<mat-label translate>t_comperator</mat-label>
		<mat-select [(ngModel)]="comparer.comparer">
			<mat-option *ngFor="let com of Comparers" [value]="com">
				{{com | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field appearance="fill" class="col s12 m4 l3" *ngIf="DataType != 0 && DataType != 4">
		<mat-label translate>t_value</mat-label>
		<input matInput [(ngModel)]="comparer.value">
	</mat-form-field>

	<mat-form-field appearance="fill" class="col s12 m4 l3" *ngIf="DataType == 0 || DataType == 4">
		<mat-label translate>t_value</mat-label>
		<mat-select [(ngModel)]="comparer.value" [compareWith]="compareFN">
			<mat-option *ngFor="let val of values" [value]="val.id">
				{{val.translate | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="col s12 m12 l2 centeringDiv">
		<button class="icon-link centeringDiv" (click)="onRemoveClick()" style="width: 100%;">
			<mat-icon style="color: white;">delete</mat-icon>
		</button>
	</div>
</div>
