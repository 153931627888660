<!-- <div class="row">
    <div class="col s12 m12 l2 sendBtn">
        <button class="icon" type="button" (click)="AddFilter()">
            <i class="material-icons">add</i>
            <label translate>t_search</label>
        </button>
    </div>
</div> -->

<app-filteritem *ngFor="let e of detailCompare" [comparer]="e" (remove)="onRemove($event)"></app-filteritem>

<!-- <ng-template filters></ng-template> -->
