import { Component, Input, OnInit } from '@angular/core';
import { TableSmallData } from '../models/table-small-data';

@Component({
	selector: 'app-table-small',
	templateUrl: './table-small.component.html',
	styleUrls: ['./table-small.component.scss']
})
export class TableSmallComponent implements OnInit {
	@Input() title: string = "";
	@Input() datasource: TableSmallData[] = []
	@Input() displayColumns: string[] = []

	public nameColumns: string[] = ["details", "1", "2"];

	constructor() { }

	ngOnInit() {
	}

}
