<mat-card appearance="outlined" [style.border-style]="isPopup ? 'none' : 'solid'">
	<mat-card-header>
		<mat-card-title>
			{{'menu.' + elastic.menuId | translate}}
			<div class="date">
				<div class="row">
					{{ elastic.timeStamp | date: (translationHelper.translate('t_time_format') | async) }}
					@if(!isPopup) {
						@if(!isArchived) {
							<mat-icon class="icon-link" (click)="deleteMeasurement()">delete</mat-icon>
						} @else {
							<mat-icon class="icon-link" (click)="restoreMeasurement()">restore_from_trash</mat-icon>
						}
					}
				</div>
			</div>
		</mat-card-title>

		<mat-card-subtitle>
			{{'device.' + elastic.device | translate}} {{elastic.completeSerial}}
		</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content>
		<div class="row s12 column-rm" style="margin: 0.5rem 0 !important;">
			<ng-template valuerow></ng-template>
		</div>

		<div class="row s12 column-rm">
			<a class="icon-link" routerLinkActive="active" routerLink="{{router.url}}/{{elastic.id}}" (click)="navigate()" >
				<mat-icon>launch</mat-icon> {{'t_details' | translate}}
			</a>
			<span class="span"></span>
			<app-report-download [detail]="elastic"></app-report-download>
		</div>
	</mat-card-content>
</mat-card>