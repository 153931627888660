import { Injectable } from '@angular/core';
import { ArticleService, ServiceOfferService, ServiceOfferWithServiceProducts, ServicePosition } from '@esders/connect-api';
import { ErrorHandlerService } from '@esders/gui';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ServiceOffer, ServiceOfferArticleServicePosition} from "@esders/connect-api";
import { OrderInformations } from 'src/app/shared/models/order-informations';
import { environment } from 'src/environments/environment';
import { ShoppingCardElement, ShoppingCardOffer, ShoppingCardServices } from 'src/app/shared/models/shopping-card-element';
import { TranslationHelperService } from '@esders/utilities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root'
})
export class ShoppingCardService {
	env = environment;

	// Das zurzeit offene Angebot.
	public currentOffer: BehaviorSubject<ShoppingCardOffer> = new BehaviorSubject<ShoppingCardOffer>(null);

	constructor(
		private _serviceOfferService : ServiceOfferService,
		private _errorhandler : ErrorHandlerService,
		private _translate: TranslationHelperService) 
	{
		this._translate.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => this.loadCurrentOffer());
	}

	/**
	 * Fügt ein neues Element zum Warenkorb hinzu.
	 */
	public async addDeviceService(artId: string, serviceId: string, artList: ArticleService[]): Promise<ShoppingCardOffer> {
		let ret: ShoppingCardOffer;

		for (const article of artList) {

			try {
				let element : ServiceOfferArticleServicePosition = {} as ServiceOfferArticleServicePosition;
				element.articleId = artId;
				element.serviceProductId = serviceId;
				element.articleServiceId = article.articleServiceId;
				element.info = (article as any).info;

				await lastValueFrom(this._serviceOfferService.putToActiveServiceOffer(this._translate.currentLang, element));
				this.loadCurrentOffer();
				ret = this.currentOffer.value;
			} catch (err) {
				console.log(err);
				this._errorhandler.error(err.error || "");
			}
		}

		return ret;
	}

	/**
	 * Lädt das aktuell offene Angebot.
	 */
	public async loadCurrentOffer(): Promise<void> {
		try {
			let res = await lastValueFrom(this._serviceOfferService.getServiceOffer(this._translate.currentLang));
            this.currentOffer.next(this.parseOffer(res));
		} catch (err) {
            if (err.status == 404)
                this.currentOffer.next(null);
			if (err.status != 404)
                this._errorhandler.error(err.error || "");
		}
	}

	public async removePosition(pos: ShoppingCardServices): Promise<void> {
		try {
			await lastValueFrom(this._serviceOfferService.deleteServiceOfferPos(pos.positionId));
			await this.loadCurrentOffer();
		} catch (err) {
			this._errorhandler.error(err.error || "");
		}
	}

	public async finishOffer(offerId: number, oi: OrderInformations): Promise<string>{//<Date> {
		try {
			let serviceOffer : ServiceOffer = {
				offerId: offerId,
				deliveryType: oi.shippingMethod,
				desiredWeek: oi.desiredDate?.toISOString(),
				costEstimate: oi.kva,
				packsAmount: oi.pakageCount,
				shipping: oi.returnShippingMethod,
				packageDiameter: oi.packageDiameter,
				yourOrder: oi.yourOrder,
				remarks: oi.remarks
			}

			let res = await lastValueFrom(this._serviceOfferService.finishServiceOffer(serviceOffer));
			this.currentOffer.next(null);
			return res.finishingTime;
		} catch (err) {
			console.log(err);
			this._errorhandler.handleHTTPError(err.error || "");
		}

		return null;
	}

	public async stornoOffer(): Promise<boolean> {
		try {
			await lastValueFrom(this._serviceOfferService.deleteServiceOffer());
			this.currentOffer.next(null);
			return true;
		} catch (err) {
			console.log(err);
			this._errorhandler.error(err.error || "");
		}

		return false;
	}

	private parseOffer(offer: ServiceOfferWithServiceProducts): ShoppingCardOffer {
		let ret = new ShoppingCardOffer();
		ret.offerNumber = offer.offerNumber;
		ret.offerId = offer.offerId;
		ret.finishingTime = offer.finishingTime;
		ret.elements = [];

		let groups = this.groupBy(offer.servicePositions, (gr: ServicePosition) => gr.serviceProductId);

		for (const group of groups.keys()) {
			const collection = groups.get(group);
			let element = new ShoppingCardElement();
			element.services = [];

			let posNum = 1;
			for (const item of collection) {
				element.articleId = item.articleId;
				element.serialNo = item.serviceProduct.serialNumber;
				element.articleName = item.serviceProduct.designation;
				element.serviceProductId = item.serviceProductId;

				element.services.push({ offerId: item.offerId, positionId: item.positionId, name: item.articleService.name, posNum: posNum, description: item.articleService.description, info: item.info });
				posNum++;
			}

			element.services = element.services.sort((a, b) => a.posNum - b.posNum);
			ret.elements.push(element);
		}

		return ret;
	}

	private groupBy(list: Array<ServicePosition>, keyGetter: (item: ServicePosition) => string) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}
}
