<a class="download-button" (click)="Download()" *ngIf="!showButton; else button">
	<i class="material-icons" [ngClass]="{'hide': this.downloadGenerate}">get_app</i>
	<i class="material-icons spin" [ngClass]="{'hide': !this.downloadGenerate}">cached</i>
</a>

<ng-template #button>
	<button class="icon" (click)="Download()" *ngIf="!asMatButton" [disabled]="downloadGenerate">
		<mat-icon [ngClass]="{'hide': this.downloadGenerate}">get_app</mat-icon>
		<mat-icon class="spin" [ngClass]="{'hide': !this.downloadGenerate}">cached</mat-icon>
		&nbsp;
		{{buttonText | translate}}
	</button>

	<button mat-button (click)="Download()" *ngIf="asMatButton" [disabled]="downloadGenerate">
		<mat-icon [ngClass]="{'hide': this.downloadGenerate}">get_app</mat-icon>
		<mat-icon class="spin" [ngClass]="{'hide': !this.downloadGenerate}">cached</mat-icon>
		{{buttonText | translate}}
	</button>
</ng-template>