import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-samsung-download-dialog',
  templateUrl: './samsung-download-dialog.component.html',
  styleUrls: ['./samsung-download-dialog.component.scss']
})
export class SamsungDownloadDialogComponent implements OnInit, AfterViewInit {
	@ViewChild("download") download : ElementRef;

	constructor(
		public dialogRef: MatDialogRef<SamsungDownloadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {filename : string, file : Blob}) 
		{
		}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.download.nativeElement.href = window.URL.createObjectURL(this.data.file);
	}

	public closeDialog() {
		// setTimeout(() => URL.revokeObjectURL(this.download.nativeElement.href), 7000);
		this.dialogRef.close();
	}
}
