import { ReportdownloadComponent } from './report-download/report-download.component';
import { FullcardComponent } from './fullcard/fullcard.component';
import { ValueRowDirective } from './directive/valuerow.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FavouriteStarComponent } from './favouriteStar/favouriteStar.component';
import { FilterComponent } from './filter/filter/filter.component';
import { BooleanValuerow } from './cards/valuerow/BooleanValuerow';
import { DoubleValuerow } from './cards/valuerow/DoubleValuerow';
import { IntegerValuerow } from './cards/valuerow/IntegerValuerow';
import { StringValuerow } from './cards/valuerow/StringValuerow';
import { TranslateValuerow } from './cards/valuerow/TranslateValuerow';
import { FilteritemComponent } from './filter/filter-item/filteritem.component';
import { ReportChooseDownloadComponent } from './report-choose-download/report-choose-download.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GenericDownloadButtonComponent } from './generic-download-button/generic-download-button.component';
import { MeasurementSearchBarComponent, OrderByMenuPipe } from './measurement-search-bar/measurement-search-bar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DownloadManagerPopupComponent } from './download-manager/download-manager-popup/download-manager-popup.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReportChooseMultiDownloadComponent } from './report-choose-multi-download/report-choose-multi-download.component';
import { GuiModule } from '@esders/gui';
import { MultiFormatImageComponent } from './multi-format-image/multi-format-image.component';
import { SamsungDownloadDialogComponent } from './generic-download-button/samsung-download-dialog/samsung-download-dialog.component';
import { ShoppingCardFabComponent } from './shopping-card-fab/shopping-card-fab.component';
import { MatBadgeModule } from '@angular/material/badge';
import { TableSmallComponent } from './table-small/table-small.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		TranslateModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatButtonModule,
		MatMenuModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		GuiModule,
		MatBadgeModule,
		MatTableModule,
		MatCheckboxModule,
		MatSlideToggleModule
	],
	declarations: [
		ValueRowDirective,
		FilterComponent,
		FilteritemComponent,
		BooleanValuerow,
		DoubleValuerow,
		IntegerValuerow,
		StringValuerow,
		TranslateValuerow,
		FavouriteStarComponent,
		ReportdownloadComponent,
		FullcardComponent,
		ReportChooseDownloadComponent,
		GenericDownloadButtonComponent,
		MeasurementSearchBarComponent,
		DownloadManagerPopupComponent,
		OrderByMenuPipe,
		ReportChooseMultiDownloadComponent,
		MultiFormatImageComponent,
		SamsungDownloadDialogComponent,
		ShoppingCardFabComponent,
		TableSmallComponent
	],
	exports: [
		FilterComponent,
		FavouriteStarComponent,
		ReportdownloadComponent,
		ReportChooseDownloadComponent,
		FullcardComponent,
		GenericDownloadButtonComponent,
		MeasurementSearchBarComponent,
		DownloadManagerPopupComponent,
		ReportChooseMultiDownloadComponent,
		MultiFormatImageComponent,
		ShoppingCardFabComponent,
		TableSmallComponent,
	]
})
export class SharedModule { }
