import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CompareService } from 'src/app/core/services/compare.service';
import { DetailCompare } from '@esders/connect-api';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
	@Input() detailCompare: DetailCompare[] = [];
	@Input()
	set addFilterEvent(val: EventEmitter<Boolean>) {
		val.subscribe(_ => {
			this.AddFilter();
		});
	}

	constructor(
		public router: Router,
		public translate: TranslateService,
		public Compare: CompareService
	) {
	}

	ngOnInit() {

	}

	AddFilter() {
		this.detailCompare.push({
			measPart: 0,
			dataType: 0,
			key: "",
			comparer: "=",
			value: "",
		});
	}

	// tslint:disable-next-line: no-shadowed-variable
	onRemove(comparer: DetailCompare) {
		let idx = this.detailCompare.indexOf(comparer);
		this.detailCompare.splice(idx, 1);
	}
}
