import { Component, OnInit, Input, NgZone, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullElasticIndex } from '@esders/connect-api';
import { TranslationHelperService } from '@esders/utilities';
import { ValueRowDirective } from '../directive/valuerow.directive';
import { CardConfigurationService } from 'src/app/core/services/card-configuration.service';
import { BooleanValuerow } from '../cards/valuerow/BooleanValuerow';
import { StringValuerow } from '../cards/valuerow/StringValuerow';
import { IntegerValuerow } from '../cards/valuerow/IntegerValuerow';
import { DoubleValuerow } from '../cards/valuerow/DoubleValuerow';
import { TranslateValuerow } from '../cards/valuerow/TranslateValuerow';
import { DialogService } from '@esders/gui';

export enum CardAction {
	DELETE,
	RESTORE
};

export interface CardActionResult {
	action: CardAction;
	measId : string;
};

@Component({
	selector: 'app-fullcard',
	templateUrl: './fullcard.component.html',
	styleUrls: ['./fullcard.component.scss']
})
export class FullcardComponent implements OnInit {
	@Input() elastic: FullElasticIndex;
	@Input() isPopup: Boolean;
	@Input() isArchived: boolean;
	@Output() cardAction: EventEmitter<CardActionResult> = new EventEmitter();

	@ViewChild(ValueRowDirective, { static: true })
	directive: ValueRowDirective;

	constructor(
		public router: Router,
		public translationHelper : TranslationHelperService,

		private _ngZone : NgZone,
		private _route: ActivatedRoute,
		private _cardService: CardConfigurationService,
		private _dialogService : DialogService
	) { }

	ngOnInit() {
		if (this._cardService.getCardConfiguration().has(this.elastic.menuId)) {
			const cardPoints = this._cardService
				.getCardConfiguration()
				.get(this.elastic.menuId);


			cardPoints.forEach((cardPoint) => {
				switch (cardPoint.dataType) {
					case 0:
						{
							const ref = this.directive.valuerowref;
							const com = ref.createComponent(BooleanValuerow);
							com.instance.setCardResultPoint(cardPoint);

							let value = this.elastic.boolResults.filter(
								(tmp) =>
									tmp.phaseId === cardPoint.phaseID &&
									tmp.resultId === cardPoint.resultID
							);
							if (value !== undefined && value.length !== 0) {
								com.instance.setValue(value[0].resultValue);
							}
						}
						break;
					case 1:
						{
							const ref = this.directive.valuerowref;
							const com = ref.createComponent(StringValuerow);
							com.instance.setCardResultPoint(cardPoint);

							let value = this.elastic.stringResults.filter(
								(tmp) =>
									tmp.phaseId == cardPoint.phaseID &&
									tmp.resultId == cardPoint.resultID
							);
							if (value !== undefined && value.length != 0) {
								com.instance.setValue(value[0].resultValue);
							}
						}
						break;
					case 2:
						{
							const ref = this.directive.valuerowref;
							const com = ref.createComponent(IntegerValuerow);
							com.instance.setCardResultPoint(cardPoint);

							let value = this.elastic.intResults.filter(
								(tmp) =>
									tmp.phaseId == cardPoint.phaseID &&
									tmp.resultId == cardPoint.resultID
							);
							if (value !== undefined && value.length != 0) {
								com.instance.setValue(value[0].resultValue);
							}
						}
						break;
					case 3:
						{
							const ref = this.directive.valuerowref;
							const com = ref.createComponent(DoubleValuerow);
							com.instance.setCardResultPoint(cardPoint);

							let value = this.elastic.doubleResults.filter(
								(tmp) =>
									tmp.phaseId == cardPoint.phaseID &&
									tmp.resultId == cardPoint.resultID
							);
							if (value !== undefined && value.length != 0) {
								com.instance.setValue(value[0].resultValue);
							}
						}
						break;
					case 4:
						{
							const ref = this.directive.valuerowref;
							const com = ref.createComponent(TranslateValuerow);
							com.instance.setCardResultPoint(cardPoint);

							let value = this.elastic.translateResults.filter(
								(tmp) =>
									tmp.phaseId == cardPoint.phaseID &&
									tmp.resultId == cardPoint.resultID
							);
							if (value !== undefined && value.length != 0) {
								com.instance.setValue(value[0].resultValue);
							}
						}
						break;
					case 5:
						{
							let value = this.elastic.stringMDE.filter(
								(tmp) =>
									tmp.field == cardPoint.mdeField
							);

							if (value !== undefined && value.length != 0) {
								if (value[0].value != "") {
									const ref = this.directive.valuerowref;
									const com = ref.createComponent(StringValuerow);

									com.instance.setCardMDEPoint(cardPoint);

									com.instance.setValue(value[0].value);
								}
							}
						}
						break;
				}
			});
		}
	}

	async deleteMeasurement() {
		var res = await this._dialogService.showYesNoDialog("t_info", "m_delete_measurement");
		if(res)
			this.cardAction.emit({action: CardAction.DELETE, measId: this.elastic.id!});
	}

	async restoreMeasurement() {
		var res = await this._dialogService.showYesNoDialog("t_info", "m_restore_measurement");
		if(res)
			this.cardAction.emit({action: CardAction.RESTORE, measId: this.elastic.id!});
	}

	// Für Leaflet
	navigate() {
		if (this.isPopup) {
			this._ngZone.run(() => {
				this.router.navigate([this.elastic.id], { relativeTo: this._route })
			});
		}
		else {
			this.router.navigate([this.elastic.id], { relativeTo: this._route });
		}
	}
}
