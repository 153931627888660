import { OnInit, ElementRef, Renderer2, Component } from '@angular/core';
import { CardPoint } from '@esders/connect-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-valuerow',
  template: '',
  styleUrls: [],
})
export abstract class ValuerowComponent implements OnInit {
	resultIsActive: boolean = true;
	identifier: string = "";
	phase: string = "";
	result: CardPoint = null;
	testMap: Map<number, string>;

	constructor(
		public el: ElementRef,
		public translate: TranslateService,
		private _renderer: Renderer2
	) {
		this.testMap = new Map<number, string>();
		this.testMap.set(13, 'evaluation.');

		this._renderer.addClass(this.el.nativeElement, "row");
		this._renderer.addClass(this.el.nativeElement, "s12");
		this._renderer.addClass(this.el.nativeElement, "column-rm");
	}

	ngOnInit() {

	}

	abstract formatValue(): string;

	public setCardResultPoint(result: CardPoint): void {
		this.resultIsActive = true;
		this.result = result;
		this.phase = 'phase.' + result.phaseID;
		this.identifier = 'result.' + result.resultID;
	}

	public setCardMDEPoint(result: CardPoint): void {
		this.resultIsActive = false;
		this.phase = result.mdeField;
		this.result = result;
	}
}
