import { Component, Input, OnInit } from '@angular/core';
import { MeasurementService } from 'src/app/core/services/measurement.service';

@Component({
	selector: 'app-report-choose-multi-download',
	templateUrl: './report-choose-multi-download.component.html',
	styleUrls: ['./report-choose-multi-download.component.scss']
})
export class ReportChooseMultiDownloadComponent implements OnInit {
	@Input() public downloadCollectionReport: boolean = false;

	constructor(public measService: MeasurementService) { }

	ngOnInit() {
	}
}
