
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@esders/gui';
import { catchError, EMPTY, finalize, shareReplay, take } from 'rxjs';
import { TranslationHelperService } from '@esders/utilities';
import { MeasDetail, ReportService } from '@esders/connect-api';
import { AuthService } from '@esders/authentificate';
import { AnalyticsService, TrackEventDownload } from '@esders/analytics';
import { downloadFile } from '../generic-download-button/generic-download-button.component';

@Component({
	selector: 'app-report-choose-download',
	templateUrl: './report-choose-download.component.html',
	styleUrls: ['./report-choose-download.component.scss']
})
export class ReportChooseDownloadComponent implements OnInit {

	public downloadGenerate: boolean = false;
	env = environment;

	@Input() detail: MeasDetail;
	@Input() isSingleReport : boolean = true;

	constructor(
		private _http: HttpClient,
		public translate: TranslateService,
		private _transhelper: TranslationHelperService,
		private _errorhandler: ErrorHandlerService,
		private _analyticsService: AnalyticsService,
		private _authService : AuthService,
		private _reportService : ReportService
	) {
	}

	ngOnInit(): void {
	}

	DownloadPDF() {
		if (this.downloadGenerate === false) {
			this.downloadGenerate = true;

			this._reportService.singleReportPdf(this.translate.currentLang, this.detail, "response").pipe(
				catchError(_ => {
					const errMsg: string = this._transhelper.translate("t_generate_report") + " " + this._transhelper.translate("t_failed");
					this._errorhandler.error(errMsg);
					return EMPTY;
				}),
				finalize(() => this.downloadGenerate = false),
				shareReplay(1),
				take(1)
			).subscribe(pdfResponse => {
				const contentDisposition = pdfResponse.headers.get('content-disposition');
				let filename = new Date().toISOString() + ".pdf";
	
				if(contentDisposition) {
					filename = contentDisposition.split(';')[1].split('filename')[1].
					split('=')[1].replace('\"', '').replace('\"', '');
				}
	
				this._analyticsService.trackEvent(new TrackEventDownload({
					name: filename,
					action: "meas-pdf"
				}));
	
				downloadFile(pdfResponse.body, filename);
			});

			// this._http.get(this.env.connectGwUri + "/report/" + this.detail.id + "/" + this.translate.currentLang + "/pdf",
			// 	{
			// 		observe: 'response',
			// 		responseType: 'blob' as 'json',
			// 		headers: {
			// 			"Authorization": "Bearer " + this._authService.getTokenValue()
			// 		}
			// 	})
			// .pipe(
			// 	catchError(_ => {
			// 		const errMsg: string = this._transhelper.translate("t_generate_report") + " " + this._transhelper.translate("t_failed");
			// 		this._errorhandler.error(errMsg);
			// 		return EMPTY;
			// 	}),
			// 	finalize(() => this.downloadGenerate = false)
			// )
			// .subscribe((response) => {
			// 	const file = new Blob([response.body as 'blob'], {
			// 		type: "application/pdf",

			// 	});
			// 	const link = document.createElement("a");
			// 	link.href = window.URL.createObjectURL(file);
			// 	const contentDisposition = response.headers.get('content-disposition');
			// 	const pdffilename = contentDisposition.split(';')[1].split('filename')[1].
			// 		split('=')[1].replace('\"', '').replace('\"', '');
			// 	link.download = pdffilename;
			// 	link.click();
			// 	this.downloadGenerate = false;

			// 	this._analyticsService.trackEvent(new TrackEventDownload({
			// 		action: 'meas-pdf',
			// 		name: pdffilename
			// 	}));
			// });
		}
	}


	DownloadCSV() {
		if (this.downloadGenerate === false) {
			this.downloadGenerate = true;

			this._http.get(this.env.connectGwUri + "/report/" + this.detail.id + "/" + this.translate.currentLang + "/csv",
				{
					observe: 'response',
					responseType: 'blob' as 'json',
					headers: {
						"Authorization": "Bearer " + this._authService.getTokenValue()
					}
				})
			.pipe(
				catchError(_ => {
					this._errorhandler.error("{0} {1}!", ["t_generate_report", "t_failed"]);
					return EMPTY;
				}),
				finalize(() => this.downloadGenerate = false)
			)
			.subscribe((response) => {
				const file = new Blob([response.body as 'blob'], {
					type: "text/csv",

				});
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(file);
				const contentDisposition = response.headers.get('content-disposition');
				const pdffilename = contentDisposition.split(';')[1].split('filename')[1].
					split('=')[1].replace('\"', '').replace('\"', '');
				link.download = pdffilename + '.csv';
				link.click();
				this.downloadGenerate = false;

				this._analyticsService.trackEvent(new TrackEventDownload({
					action: 'meas-csv',
					name: pdffilename + '.csv'
				}));
			});
		}
	}

	DownloadXLSX() {
		if (this.downloadGenerate === false) {
			this.downloadGenerate = true;

			this._http.get(this.env.connectGwUri + "/report/" + this.detail.id + "/" + this.translate.currentLang + "/xlsx",
				{
					observe: 'response',
					responseType: 'blob' as 'json',
					headers: {
						"Authorization": "Bearer " + this._authService.getTokenValue()
					}
				})
			.pipe(
				catchError(_ => {
					this._errorhandler.error("{0} {1}!", ["t_generate_report", "t_failed"]);
					return EMPTY;
				}),
				finalize(() => this.downloadGenerate = false)
			)
			.subscribe((response) => {
				const file = new Blob([response.body as 'blob'], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(file);
				const contentDisposition = response.headers.get('content-disposition');
				const xlsxfilename = contentDisposition.split(';')[1].split('filename')[1].
					split('=')[1].replace('\"', '').replace('\"', '');
				link.download = xlsxfilename + '.xlsx';
				link.click();
				this.downloadGenerate = false;

				this._analyticsService.trackEvent(new TrackEventDownload({
					action: 'meas-xlsx',
					name: xlsxfilename + '.xlsx'
				}));
			});
		}
	}
}
