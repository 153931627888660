import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlerService } from '@esders/gui';
import { catchError, EMPTY, finalize } from 'rxjs';
import { SamsungDownloadDialogComponent } from './samsung-download-dialog/samsung-download-dialog.component';
import { AuthService } from '@esders/authentificate';
import { AnalyticsService, TrackEventDownload } from '@esders/analytics';

export const downloadFile = (blob: Blob, fileName: string) => {
	const link = document.createElement('a');
	// create a blobURI pointing to our Blob
	link.href = URL.createObjectURL(blob);

	link.download = fileName;
	// some browser needs the anchor to be in the doc
	document.body.append(link);
	link.click();
	link.remove();
	// in case the Blob uses a lot of memory
	setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

@Component({
	selector: 'app-generic-download-button',
	templateUrl: './generic-download-button.component.html',
	styleUrls: ['./generic-download-button.component.scss']
})
export class GenericDownloadButtonComponent implements OnInit {
	// env = environment;

	@Input() uri: string = "";
	@Input() name: string = "undefined"; // Name des Downloads fürs Tracking
	@Input() mimetype: string = "application/pdf";
	@Input() showButton: boolean = false;
	@Input() buttonText: string = "";
	@Input() asMatButton: boolean = false;

	@Output() downloading = new EventEmitter<boolean>();

	public downloadGenerate: boolean = false;

	constructor(
		private _http: HttpClient,
		private _analyticsService: AnalyticsService,
		private _errorhandler: ErrorHandlerService,
		private _dialog: MatDialog,
		private _authService : AuthService
	) { }

	ngOnInit() {
	}

	/**
	 * Lädt eine Datei runter.
	 */
	public Download() {
		if (this.downloadGenerate === false) {
			this.downloadGenerate = true;
			this.downloading.next(true);

			this._http.get(this.uri,
				{
					observe: 'response',
					responseType: 'blob' as 'json',
					headers: {
						"Authorization": "Bearer " + this._authService.getTokenValue()
					}
				})
				.pipe(
					catchError(err => {
						if(err.status == 418)
							this._errorhandler.error("m_sales_offer_blocked");
						else
							this._errorhandler.error("{0} {1}!", ["t_generate_report", "t_failed"]);

						return EMPTY;
					}),
					finalize(() => {
						this.downloadGenerate = false;
						this.downloading.next(false);
					})
				)
				.subscribe((response: any) => {
					const contentDisposition = response.headers.get('content-disposition');
					let filename = new Date().toISOString();

					if(contentDisposition) {
						filename = contentDisposition.split(';')[1].split('filename')[1].
						split('=')[1].replace('\"', '').replace('\"', '');
					} else {
						switch(this.mimetype) {
							case "application/pdf":
								filename += ".pdf";
							break;

							case "application/zip":
								filename += ".zip";
							break;
						}
					}

					const pdffilename = filename;

					this._analyticsService.trackEvent(new TrackEventDownload({
						name: pdffilename,
						action: this.name
					}));

					// Workaround für Samsung Internet. Obwohl dieser aus Chromium basiert, verhält er sich in / nach dem Subscribe anders.
					// Der Downloadlink welcher erstellt und im Code geklickt wird, triggerd an dieser Stelle den Download nicht.
					// Deshalb geht ein Dialog auf, wo der Nutzer auf diesen Link klicken muss, dann klappt das.
					if(navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
						this._dialog.open(SamsungDownloadDialogComponent, {
							disableClose: true,
							data: {filename: pdffilename, file: response.body}
						});
					} else {
						downloadFile(response.body, pdffilename);
					}

					this.downloadGenerate = false;
					this.downloading.next(false);
				});
		}
	}
}
