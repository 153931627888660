import { ValuerowComponent } from './valuerow.component';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-valuerowboolean',
	templateUrl: './valuerow.component.html',
	styleUrls: ['./valuerow.component.scss']
})

export class BooleanValuerow extends ValuerowComponent {

	public value: Boolean;

	constructor(
		public el: ElementRef,
		public translate: TranslateService,
		renderer: Renderer2
	) {
		super(el, translate, renderer);
	}

	public setValue(value: Boolean) {
		this.value = value;
	}

	formatValue(): string {
		return this.value ? 'true' : 'false';
	}
}
