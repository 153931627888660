<mat-expansion-panel>
	<mat-expansion-panel-header>
		<mat-panel-title>
			@if(downloadManager.allDownloadsFinished() | async) {
				<mat-icon class="icon-link" (click)="hide()">close</mat-icon>
			} @else {
				<mat-spinner diameter="24"></mat-spinner>
			}

			<span>&nbsp; {{'t_downloads' | translate}}</span>
		</mat-panel-title>
	</mat-expansion-panel-header>
	<div class="download-content">
		@for (job of downloadManager.getJobIds() | async; track job.jobId) {
			<div class="download-content-entry">
				<div class="file">
					@if(job.state == 0 || job.state == 1) {
						<mat-spinner diameter="24"></mat-spinner>
					} @else if(job.state == 2) {
						<app-generic-download-button [uri]="this.env.connectGwUri + '/ConnectExport/' + job.jobId + '/stream' + ((job.token != '') ? '?orderNumber=' + job.orderId + '&token=' + job.token : '')" mimetype="application/zip" name="multi"></app-generic-download-button>
					}
					<span class="job-id">{{job.jobId}}</span>
					<span style="padding: 0 5px;">&dash;</span>
					<span class="light"> {{job.type | translate}} </span>
				</div>
	
				@if(job.state == 3) {
					<mat-error>{{job.errorMessage | translate}}</mat-error>
				}
			</div>
		}
	</div>
</mat-expansion-panel>
