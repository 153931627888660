<a class="icon-link" [matMenuTriggerFor]="menu" [ngClass]="{'disable-menu': (measService.downloading | async)}">
	<mat-icon [ngClass]="{'hide': (measService.downloading | async)}">get_app</mat-icon>
	<mat-icon class="spin" [ngClass]="{'hide': !(measService.downloading | async)}">cached</mat-icon>
</a>
<mat-menu #menu="matMenu">
	<button mat-menu-item (click)="measService.downloadMeasList()">
		<span translate>t_download_meas_list</span>
	</button>
	<button mat-menu-item (click)="measService.multiExport()" *ngIf="!downloadCollectionReport">
		<span translate>t_download_measurements</span>
	</button>
	<button mat-menu-item (click)="measService.downloadCollectionReport()" *ngIf="downloadCollectionReport">
		<span translate>t_download_collection_report</span>
	</button>
</mat-menu>
